.error {
  margin-bottom: 10px;
}

.img {
  margin-bottom: 68px;
}

.title {
  font-family: Formular, sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.43px;
  text-align: center;
  color: #222222;
  margin-bottom: 17px;
}

.description {
  line-height: 1.47;
  letter-spacing: 0.51px;
  text-align: center;
  color: #868e9c;
  margin-bottom: 60px;
}
