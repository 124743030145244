.header {
  margin-bottom: 6px;
}

.connected {
  font-size: 14px;
  line-height: 1.79;
  letter-spacing: 0.42px;
  color: #868e9c;
}

.address {
  font-family: Formular, sans-serif;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.55px;
  color: #222222;
  margin-bottom: 30px;
}
