.link {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.39px;
  color: #2e50cf;
  margin-right: 26px;
}

.link:hover,
.link:focus {
  color: #486bef;
}

.link:hover g {
  opacity: 0.7;
}

.link:focus g {
  opacity: 0.7;
}

.link:active {
  color: #00238f;
}
