.modalWrapper {
  top: 0;
  left: 0;
  z-index: var(--modal-z-index);
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(134, 142, 156, 0.7);
}

.modal {
  border-radius: 20px;
  border: none;
  box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);
  background: var(--white);
  padding: 40px;
  min-width: 570px;
  z-index: 10;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}

.header {
  width: 100%;
  margin-bottom: 25px;
}

.withDescription {
  margin-bottom: 0;
}

.title {
  font-family: Formular, sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.43px;
  color: #222222;
  line-height: 27px;
}

.description {
  display: block;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.51px;
  color: #868e9c;
  margin-bottom: 25px;
}

.close {
  width: 27px;
  height: 27px;
  align-self: flex-start;
  outline: none;
  border: none;
  cursor: pointer;
  background: transparent;
  justify-content: flex-start;
  align-items: flex-end;
  padding: calc((27px - 16px) / 2) 0 0;
}

.content {
  position: relative;
}

@media (max-width: 1014px) {
  .modal {
    width: 95%;
    min-width: unset;
    margin: 0 auto;
    padding: 40px 25px;
  }
}
