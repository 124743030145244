.select {
  display: flex;
  flex-direction: column;
}

.selectValue {
  width: 100%;
  height: 45px;
  border: none;
  border-bottom: 1px solid #eaebf0;
  outline: none;
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 45px;
  letter-spacing: 0.51px;
  color: #525252;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rotateIcon {
  transform: rotate(-180deg);
}

.selectContent {
  position: relative;
}

.label {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: 0.42px;
  color: #868e9c;
}

.selectItems {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50px;
  border-radius: 8px;
  box-shadow: 0 30px 40px 0 rgba(73, 76, 82, 0.2);
  background-color: var(--white);
  z-index: 99;
  overflow: hidden;
  max-height: 200px;
  overflow-y: scroll;
}

/* width */
.selectItems::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.selectItems::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.selectItems::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.selectItems::-webkit-scrollbar-thumb:hover {
  background: #555;
}
