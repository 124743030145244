.notification {
  min-height: 46px;
  border-radius: 8px;
  color: var(--white);
  width: 400px;
  padding: 8px 20px;
  transition: all linear 0.25s;
  animation: show 0.2s normal forwards ease-in-out;
  animation-iteration-count: 1;
  margin-left: -200px;
  top: 23px;
  left: 50%;
  position: fixed;
  z-index: 999999;
}

.success {
  box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.2);
  background-color: #222222;
}

.failure {
  box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.2);
  background-color: #fd5d83;
}

.img {
  width: 15px;
  min-width: 15px;
  margin-right: 20px;
}

.text {
  text-transform: uppercase;
  font-family: Formular, sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-align: center;
  color: var(--white);
}

@keyframes show {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
