.wrapper {
  min-height: 500px;
}

.headers {
  margin-bottom: 30px;
}

.addressHeader,
.balanceHeader {
  font-size: 13px;
  line-height: 1.92;
  letter-spacing: 0.5px;
  color: #868e9c;
}

.addresses {
  margin-bottom: 22px;
}

.address {
  font-family: Formular, sans-serif;
  font-size: 17px;
  letter-spacing: 0.33px;
  color: #222222;
  cursor: pointer;
  margin-bottom: 8px;
  height: 43px;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 20px;

  &:hover,
  &:focus {
    background-color: rgba(46, 80, 207, 0.1);
  }
}

.addressActive {
  background-color: rgba(46, 80, 207, 0.1);
}

.addressText {
  width: 200px;
}

.balance {
  text-align: right;
}

.radio {
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 15px;
  border: solid 2px #dadfe9;
  border-radius: 50%;
}

.radioActive {
  border: none;
  background: rgba(46, 80, 207, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 8px;
    min-width: 8px;
    height: 8px;
    background: #2e50cf;
    border-radius: 50%;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #eaebf0;
  margin-bottom: 20px;
}

.button {
  line-height: 46px;
  letter-spacing: 0.45px;
}

.buttonDisabled {
  color: #868e9c !important;
  cursor: default !important;
}

.previousIcon {
  vertical-align: middle;
  margin-right: 8px;
  line-height: 1.67;
  transform: rotate(180deg);
  opacity: 1 !important;
}

.nextIcon {
  vertical-align: middle;
  margin-left: 8px;
  line-height: 1.67;
  opacity: 1 !important;
  margin-top: 1px;
}

.contractDataTitle {
  font-family: Formular, sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.43px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: -35px;
}

.contractDataDescription {
  font-size: 17px;
  line-height: 1.47;
  letter-spacing: 0.51px;
  text-align: center;
  color: #868e9c;
  margin-bottom: 60px;
  max-width: 420px;

  b {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }
}

.contractDataImg {
  margin-bottom: 80px;
}
