.selectItem {
  height: 50px;
  padding: 0 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.selectItem:hover {
  background: rgba(73, 76, 82, 0.2);
}
