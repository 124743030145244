.select {
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 35px;
  }
}

.button {
  width: 100%;
}
