html,
body {
  padding: 0;
  margin: 0;
  background-color: #f8f9fc;
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

:root {
  --modal-z-index: 999;
  --notification-z-index: 9999;
  --white: #ffffff;
  --black: #000000;
}

#wcm-modal {
  z-index: 99999;
}

.rc-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
