.container {
  width: 100%;
  max-width: 1185px;
  margin: 0 auto;
  padding: 0 15px;
}

.button-link {
  height: 46px;
  border-radius: 8px;
  border: solid 1px #eaebf0;
  background-color: var(--white);
  font-family: 'Formular', sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 1.5px;
  text-align: center;
  text-transform: uppercase;
  color: #2e50cf;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f8f9fc;
  }

  &:focus,
  &:active {
    background: #eceff5;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-start {
  align-items: center;
  justify-content: flex-start;
}

.flex-end {
  align-items: center;
  justify-content: flex-end;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-end {
  align-items: center;
  justify-content: flex-end;
}

.flex-space-between {
  align-items: center;
  justify-content: space-between;
}

.home,
.lockup {
  position: relative;
  overflow: hidden;
}

.img-top {
  display: none;
  position: absolute;
  left: calc((100% - 1170px) / 2 + 400px);
  top: 0;
  width: 1134px;
  height: 410px;
}

.img-bottom {
  position: absolute;
  left: calc((100% - 1595px) / 2 - 200px);
  width: 850px;
  bottom: 0;
}

.img-mobile-top {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

.hidden-desktop {
  display: none !important;
}

.hidden-mobile {
  display: block;
}

.disabled-menu {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.disabled-menu:hover a {
  color: #222222 !important;
}

.disabled-menu:hover a:before {
  opacity: 0 !important;
}

.img-mobile-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  display: none;
}

.tippy-popper .tippy-tooltip.light-theme {
  border-radius: 8px;
  background: #ffffff;
}

@media (max-width: 1014px) {
  .navHomeTopLinks {
    display: none;
  }

  .img-mobile-top {
    display: none;
  }

  .img-mobile-bottom {
    width: 100%;
    display: block;
    bottom: 200px;
  }

  .img-top,
  .img-bottom {
    display: none;
  }

  .container {
    width: 100%;
  }

  .hidden-desktop {
    display: block !important;
  }

  .hidden-mobile {
    display: none !important;
  }
}

.blur {
  filter: blur(10px);
}

.overflow-hidden {
  overflow: hidden;
}

.plx-calculate {
  visibility: unset !important;
  transform: translateY(-240px);
}

.checkbox-tooltip {
  position: absolute;
  right: 2px;
  top: 4px;
}

.checkbox-tooltip-basic {
  position: absolute;
  right: 38px;
  top: 4px;
}
