.row {
  width: 100%;
  height: 80px;
  border-radius: 8px;
  background-color: #f8f9fc;
  padding: 32px 24px;
  cursor: pointer;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 1014px) {
    padding: 16px;
  }
}

.row:hover,
.row:focus {
  background-color: #eceff5;
}

.logo {
  width: 40px;
}

.content {
  margin-left: 32px;

  @media (max-width: 1014px) {
    margin-left: 16px;
  }
}

.title {
  font-size: 17px;
  line-height: 1.47;
  letter-spacing: 0.51px;
  color: var(--black);

  & > span {
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: #868e9c;
  }
}

.description {
  font-size: 14px;
  letter-spacing: 0.51px;
  color: #868e9c;
  margin-top: 3px;

  @media (max-width: 1014px) {
    margin-top: 0;
  }
}
