.wrapper {
  width: auto;
  font-family: 'Formular', sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 18px;
  border: none;
  cursor: pointer;
  justify-content: center;
  transition: background-image 1s ease-in-out;

  &:focus {
    outline: none;
    text-decoration: none;
  }

  &:disabled {
    border-radius: 8px;
    border: solid 1px #eaebf0;
    background: transparent;
    color: #868e9c;
    box-shadow: none;
    cursor: default;

    &:focus,
    &:hover {
      box-shadow: none;
      outline: none;
      text-decoration: none;
      background: none;
      color: #868e9c;
    }
  }
}

.primary {
  box-shadow: 0 15px 25px 0 rgba(46, 80, 207, 0.3);
  background-image: linear-gradient(114deg, #2e50cf, #0029a6 100%);
  color: #ffffff;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 8px;
  height: 46px;

  &:hover,
  &:focus {
    box-shadow: 0 15px 35px 0 rgba(46, 80, 207, 0.3);
    background-image: linear-gradient(114deg, #486bef 2%, #123fc7 100%);
  }

  &:active {
    box-shadow: 0 15px 15px 0 rgba(46, 80, 207, 0.3);
    background-image: linear-gradient(114deg, #1f41bf, #00238f 99%);
  }
}

.green {
  color: #ffffff;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 8px;
  height: 46px;
  box-shadow: 0 15px 25px 0 rgba(0, 186, 194, 0.3);
  background-image: linear-gradient(105deg, #00bac2, #039fa6);

  &:hover,
  &:focus {
    box-shadow: 0 15px 35px 0 rgba(0, 186, 194, 0.3);
    background-image: linear-gradient(114deg, #00cbd4, #00b0b8 100%);
  }

  &:active {
    box-shadow: 0 15px 25px 0 rgba(0, 186, 194, 0.3);
    background-image: linear-gradient(114deg, #039fa6, #007e84 100%);
  }
}

.secondary {
  background-color: #f8f9fc;
  color: #868e9c;
  letter-spacing: 0.39px;
  font-size: 13px;
  border-radius: 5px;
  height: 32px;

  &:hover,
  &:focus {
    background-color: #eceff5;
    color: #2e50cf;
  }

  &:active {
    background-color: #ebedf4;
    color: #00238f;
  }
}

.white {
  border: solid 1px #eaebf0;
  background-color: var(--white);
  color: #2e50cf;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 8px;
  height: 46px;
  will-change: color, background-color, border-color;
  transition: all linear 0.2s;

  &:hover {
    color: #486bef;
    border: solid 1px #eaebf0;
    background-color: #f8f9fc;
  }

  &:active {
    color: #00238f;
    border: solid 1px #eceff5;
    background-color: #eceff5;
  }
}

.icon {
  margin-right: 8px;

  &.onlyIcon {
    margin-right: 0;
  }

  svg {
    display: block;
  }
}

.iconContained {
  border: none;
  background: none;
  box-shadow: none;

  path,
  g,
  rect {
    fill: #222222;
  }

  g > rect {
    stroke: #222222;
  }

  &:hover {
    path,
    g,
    rect {
      fill: #486bef;
    }

    g > rect {
      stroke: #486bef;
    }
  }

  &:focus {
    path,
    g,
    rect {
      fill: #00238f;
    }

    g > rect {
      stroke: #00238f;
    }
  }

  &:hover,
  &:focus {
    background-image: none;
    box-shadow: none;
  }
}

.textContained {
  color: #2e50cf;
  border: none;
  background: none;
  box-shadow: none;
  font-weight: 500;
  text-transform: inherit;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  padding: 0;
  letter-spacing: 0.38px;

  .icon {
    margin-right: 10px;
  }

  &:hover,
  &:focus {
    background: none;
    box-shadow: none;
    border: none;

    svg {
      opacity: 0.7;
    }
  }

  &:hover {
    color: #486bef;
  }

  &:focus {
    color: #00238f;
  }
}
